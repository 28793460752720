import { LitElement, html, css } from 'lit';
import { property, customElement } from 'lit/decorators.js';

@customElement('set-cookie')
export class SetCookie extends LitElement {
  static styles = css`
    .cookie-handler {
      width: 100%;
      margin: 4px 2px;
      position: relative;
    }
    .button {
      display: block;
      width: 100%;
      height: 34px;
      border-radius: 5px;
      color: white;
      border: none;
      cursor: pointer;
      text-align: center;
      line-height: 34px;
      margin-bottom: 10px;
    }
    .post-button {
      background-color: #2196f3;
    }
    .post-button:hover {
      background-color: #1976d2;
    }
  `;

  @property({ type: Boolean })
  debugMode: boolean = false;

  async handlePostCookie() {
    if (this.debugMode) {
      console.log('Sending POST request to https://api.ayetu.net/post-cookie');
    }

    try {
      const response = await fetch('https://api.ayetu.net/post-cookie', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ contactId: 'defaultContactId' }), // Replace with default value or logic as needed
        credentials: 'include', // Important to include cookies in the request
      });
      const data = await response.json();
      console.log('POST Cookie response:', data);
    } catch (error) {
      console.error('Error posting cookie:', error);
    }
  }

  render() {
    return html`
      <div class="cookie-handler">
        <button class="button post-button" @click=${this.handlePostCookie}>POST Cookie</button>
      </div>
    `;
  }
}
